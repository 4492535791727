@import "mixin";

$offcanvas-width: 250px !default;
$color-base: #191919;

@charset "UTF-8";

@include mq('min', 'lg') {
}

body{
  padding-top: 75px;
  // padding-top: 20px;
  font-family: "A1 Gothic L", sans-serif;
}

.navbar{
  .navbar-brand{
    letter-spacing: 0.11em;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    line-height: 1.2;
    font-size: 1.56rem;
    span{
      font-size: 54%;
      display: block;
      font-weight: normal;
    }
    img{
      height: 50px;
    }
  }
  .navbar-nav{
    display: flex;
    flex-direction:row;
    li {
      padding-left:.5rem;
      .nav-link{
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.2;
        color: #000;
        span{
          display: block;
          font-size: 50%;
        }
      }
      &+li{
        padding-left:1rem;
      }
    }
  }
  // .navbar-toggler{
  //   padding: 0;
  //   border-radius: 0;
  //   width:22px;
  //   height:22px;
  //   position:relative;
  //   cursor:pointer;
  //   overflow:hidden;
  //   z-index:9999;
  //   border: 0;
  //   .menu1{
  //     width:22px;
  //     height:3px;
  //     background-color: #fff;
  //     top:3px;
  //     position:absolute;
  //   }
  //   .menu2{
  //     width:22px;
  //     height:3px;
  //     background-color: #fff;
  //     top:10px;
  //     position:absolute;
  //     transition: all 0.1s ease-in-out;
  //   }
  //   .menu3{
  //     width:22px;
  //     height:3px;
  //     background-color: #fff;
  //     top:17px;
  //     position:absolute;
  //   }
  //   .menu1,.menu3{
  //     transition: all 0.5s ease-out;
  //   }
  //   .menuclick1{
  //     top:8px;
  //     width: 20px;
  //     transform: rotate(135deg);
  //   }
  //   .menuclick2{
  //     background-color:rgba(255,255,255,0);
  //    }
  //   .menuclick3{
  //     top:8px;
  //     width: 20px;
  //     transform: rotate(-135deg);
  //   }
  // }
  // .navbar-collapse{
  //   @include mq('min', 'lg') {
  //     justify-content: flex-end;
  //   }
  //   @include mq('max', 'lg') {
  //     ul{
  //       text-align: center;
  //       max-width: 720px;
  //       margin:0 auto;
  //       li{
  //         &+li{
  //           border-top: 1px solid rgba(255,255,255,.4);
  //           border-radius: 0;
  //         }
  //         a{
  //           padding:.7rem 1rem;
  //           &:hover{
  //             background: rgba(255,255,255,.1);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

nav#pagenav{
  position: fixed;
  top:40%;
  right:10px;
  z-index: 9999;
  ul{
    li{
      text-align: right;
      font-size: .75rem;
      line-height: 2;
      a{
        color: $color-base;
        display: inline;
        border-bottom: 1px solid rgba(255,255,255,.1);
        padding: 2px 0;
        text-decoration: none;
        // background: rgba(255,255,255,.4);
        &:hover{
          border-bottom-color: $color-base;
        }
      }
    }
  }
}

// .home2018{
//
//   .contents{
//     h1, h2, h3{font-family:"A1 Gothic M", sans-serif;}
//     h1, h2, h3, dt, th{
//       font-weight: normal;
//     }
//     h1{
//       line-height: 1.4;
//     }
//     p{
//       font-size: 1.1rem;
//       line-height: 1.6;
//       a{
//         color: $color-base;
//         text-decoration: underline;
//       }
//     }
//   }
//   #feature{
//     max-width: 420px;
//     max-height: 420px;
//     margin:0 auto;
//     img{
//       max-height: 420px;
//     }
//     @include mq('max', 'sm') {
//       overflow:hidden;
//     }
//   }
//   #lead{
//     h1{
//       font-weight: normal;
//     }
//   }
//
//   #about, #reasons, #works, #outline{
//     border-top: 1px solid #ccc;
//   }
//   #reasons{
//   }
//
//   #works{
//     .col-6{
//       li, p{
//         font-size: .9rem;
//       }
//     }
//     .slider{
//       img{
//         max-height: 300px;
//         @include mq('max', 'sm') {
//           max-height: 190px;
//         }
//       }
//     }
//   }
//
//   #outline{
//     table{
//       font-size: .95rem;
//       .nowrap{
//         white-space: nowrap;
//       }
//       th{
//         border-top: 0;
//         padding: .2rem .5rem .2rem .2rem;
//       }
//       td{
//         padding: .2rem;
//         border-top: 0;
//         span{
//           font-size: .8rem;
//         }
//         a{
//           color: #333;
//         }
//       }
//     }
//     img{
//       max-height: 500px;
//     }
//   }
//
//   #mitsumori{
//     background: #eee;
//     p.note{
//       font-size: .85rem;
//     }
//     a{
//       color: #fff;
//     }
//   }
//   #faq{
//     dl{
//       border-bottom:1px dashed #ddd;
//     }
//     dt{
//       font-size: 1.1rem;
//       cursor: pointer;
//     }
//     .faq-close{
//       dt{
//         position: relative;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         &::after{
//           content: '+';
//           font-weight: normal;
//           font-size: 2rem;
//           line-height: .8;
//         }
//       }
//       dd{
//         display: none;
//       }
//     }
//   }
//
//   #contact{
//     background: #eee;
//     p{
//       font-size: 1.2rem;
//     }
//     p.note{
//       font-size: .85rem;
//     }
//     p#mail-form-footer{
//       .btn{
//         font-size: 1.2rem;
//       }
//     }
//     #mail_form{
//       dt{
//         .required{
//           color: #f33;
//           font-size: .9rem;
//         }
//       }
//       dd{
//         position: relative;
//         min-height: 1px;
//         padding-right: 15px;
//         padding-left: 15px;
//         @include mq('min', 'md') {
//           flex: 0 0 66.66667%;
//           max-width: 66.66667%;
//         }
//         @include mq('max', 'md') {
//           flex: 0 0 100%;
//           max-width: 100%;
//         }
//         .error_blank{
//           color: #f33;
//           font-size: .9rem;
//         }
//       }
//     }
//   }
// }
// .home2019{
//   .contents{
//     h1, h2, h3{
//       font-family:"A1 Gothic M", sans-serif;
//       font-feature-settings : "palt";
//     }
//     h1, h2, h3, dt, th{
//       font-weight: normal;
//     }
//     h1{
//       line-height: 1.4;
//     }
//     p{
//       font-size: 1.1rem;
//       line-height: 1.6;
//       a{
//         color: $color-base;
//         text-decoration: underline;
//       }
//     }
//   }
//   #feature{
//     max-width: 420px;
//     max-height: 420px;
//     margin:0 auto;
//     img{
//       max-height: 420px;
//     }
//     @include mq('max', 'sm') {
//       overflow:hidden;
//     }
//   }
//   #lead{
//     h1{
//       font-weight: normal;
//     }
//   }
//
//   #about, #reasons, #works, #outline{
//     border-top: 1px solid #ccc;
//   }
//   #reasons{
//     img{
//       background: #f9f9f9;
//     }
//   }
//
//   #about{
//     #about-casting{
//       background: #f9f9f9;
//     }
//   }
//
//   #works{
//     .col-6{
//       li, p{
//         font-size: .9rem;
//       }
//     }
//     .modal{
//       a{
//         color:#fff;
//         text-decoration: none;
//       }
//       p{
//         font-size: .9rem;
//         cursor: pointer;
//       }
//     }
//     .slider{
//       img{
//         max-height: 300px;
//         @include mq('max', 'sm') {
//           max-height: 190px;
//         }
//       }
//     }
//   }
//
//   #outline{
//     table{
//       font-size: .95rem;
//       .nowrap{
//         white-space: nowrap;
//       }
//       th{
//         border-top: 0;
//         padding: .2rem .5rem .2rem .2rem;
//         font-family:"A1 Gothic M", sans-serif;
//       }
//       td{
//         padding: .2rem;
//         border-top: 0;
//         span{
//           font-size: .8rem;
//         }
//         a{
//           color: #333;
//         }
//       }
//     }
//     dl{
//       dt{
//         font-family:"A1 Gothic M", sans-serif;
//         margin-bottom: .2rem;
//       }
//       dd{
//         margin-bottom: 1.6rem;
//         a{
//           color: $color-base;
//           text-decoration: underline;
//         }
//         ol{
//           li{
//             line-height: 1.7;
//             ul.list-unstyled{
//               li{
//                 display: inline-block;
//               }
//             }
//           }
//         }
//       }
//     }
//     img{
//       max-height: 500px;
//     }
//     ul{
//       li{
//         line-height: 1.8;
//       }
//     }
//   }
//
//   #mitsumori{
//     background: #eee;
//     p.note{
//       font-size: .85rem;
//     }
//     a{
//       color: #fff;
//     }
//   }
//   #faq{
//     dl{
//       border-bottom:1px dashed #ddd;
//     }
//     dt{
//       font-size: 1.1rem;
//       cursor: pointer;
//         position: relative;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//       &::after{
//         content: '+';
//         font-weight: normal;
//         font-size: 2rem;
//         line-height: .8;
//         opacity: 0;
//       }
//     }
//     dd{
//       padding-left: 1rem;
//       border-left: 3px solid #666;
//     }
//     .faq-close{
//       dt{
//         &::after{
//           opacity: 1;
//         }
//       }
//       dd{
//         display: none;
//       }
//     }
//   }
//
//   #contact{
//     background: #eee;
//     p{
//       font-size: 1.2rem;
//     }
//     p.note{
//       font-size: .85rem;
//     }
//     p#mail-form-footer{
//       .btn{
//         font-size: 1.2rem;
//       }
//     }
//     #mail_form{
//       dt{
//         .required{
//           color: #f33;
//           font-size: .9rem;
//         }
//       }
//       dd{
//         position: relative;
//         min-height: 1px;
//         padding-right: 15px;
//         padding-left: 15px;
//         @include mq('min', 'md') {
//           flex: 0 0 66.66667%;
//           max-width: 66.66667%;
//         }
//         @include mq('max', 'md') {
//           flex: 0 0 100%;
//           max-width: 100%;
//         }
//         .error_blank{
//           color: #f33;
//           font-size: .9rem;
//         }
//       }
//     }
//   }
// }

.home2021{
  .btn-dark:hover{
    background: #fff;
    color: #1d2124;
  }
  .navbar{
    .covid{
      width: 100vw;
      color: #fff;
      background: #CC3333;
      margin:-10px -16px 15px;
      border-bottom: 2px solid #CC3333;
      a{
        color: #fff;
        padding: 16px 10px 13px;
        display: block;
        text-align: center;
        @include mq('min', 'lg') {
          font-size: 21px;
        }
        @include mq('max', 'lg') {
          font-size: 14px;
        }
      }
    }
  }
  .contents{
    h1, h2, h3{
      font-family:"A1 Gothic M", sans-serif;
      font-feature-settings : "palt";
    }
    h1, h2, h3, dt, th{
      font-weight: normal;
    }
    h1{
      line-height: 1.4;
    }
    p{
      font-size: 1.1rem;
      line-height: 1.6;
      a{
        color: $color-base;
        text-decoration: underline;
      }
    }

    padding-top: 60px;
  }
  #feature{
    max-width: 420px;
    max-height: 420px;
    margin:0 auto;
    img{
      max-height: 420px;
    }
    @include mq('max', 'sm') {
      overflow:hidden;
    }
  }
  #lead{
    h1{
      font-weight: normal;
      @include mq('max', 'sm') {
        font-size: 2.3rem;
      }
    }
  }

  #about, #reasons, #works, #outline{
    border-top: 1px solid #ccc;
  }
  #reasons{
    img{
      background: #f9f9f9;
    }
  }
  #covid{
    margin-bottom: 3px;
    h2{
      color: #fff;
      background: #CC3333;
      text-align: center;
      padding:17px;
      margin-bottom: 10px;
    }
    h3{
      text-align: center;
      line-height: 2;
    }
    p{
      line-height: 2.05;
      margin:0 0 53px;
      letter-spacing: .05rem;
    }
    @include mq('min', 'lg') {
      h2, h3{
        font-size: 21px;
      }
    }
    @include mq('max', 'lg') {
      h2, h3{
        font-size: 18px;
      }
      p{
        margin:0 0 30px;
        letter-spacing: .01rem;
      }
    }
    @include mq('max', 'sm') {
      h3{
        font-size: 17px;
      }
    }
    #covid-mitsumori{
      p{
        line-height: 1.6;
      }

      @include mq('min', 'lg') {
        margin-top: 20px;
        padding-bottom: 15px;
      }
      @include mq('max', 'lg') {
        margin-top: 0;
        padding-bottom: 15px;
      }
    }
  }
  #about{
    #about-casting{
      background: #f9f9f9;
    }
  }

  #works{
    .col-6{
      li, p{
        font-size: .9rem;
      }
    }
    .modal{
      a{
        color:#fff;
        text-decoration: none;
      }
      p{
        font-size: .9rem;
        cursor: pointer;
      }
    }
    .slider{
      img{
        max-height: 300px;
        @include mq('max', 'sm') {
          max-height: 190px;
        }
      }
    }
  }

  #outline{
    table{
      font-size: .95rem;
      .nowrap{
        white-space: nowrap;
      }
      th{
        border-top: 0;
        padding: .2rem .5rem .2rem .2rem;
        font-family:"A1 Gothic M", sans-serif;
      }
      td{
        padding: .2rem;
        border-top: 0;
        span{
          font-size: .8rem;
        }
        a{
          color: #333;
        }
      }
    }
    dl{
      dt{
        font-family:"A1 Gothic M", sans-serif;
        margin-bottom: .2rem;
      }
      dd{
        margin-bottom: 1.6rem;
        a{
          color: $color-base;
          text-decoration: underline;
        }
      }
    }
    img{
      max-height: 500px;
    }
    ul{
      li{
        line-height: 1.8;
      }
    }
    #services{
      ol{
        li{
          line-height: 1.7;
          ul.list-unstyled{
            li{
              display: inline-block;
            }
          }
        }
      }
    }
    #customer{
      ul{
        li{
          font-size: 95%;
        }
      }
    }
  }

  #mitsumori, #covid-mitsumori{
    background: #ff4;
    p.note{
      font-size: .85rem;
    }
    a{
      color: #fff;
      &:hover{
        background: #fff;
        border-color: #1d2124;
        color: #1d2124;
      }
    }
  }
  #faq{
    dl{
      border-bottom:1px dashed #ddd;
    }
    dt{
      font-size: 1.1rem;
      cursor: pointer;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
      &::after{
        content: '+';
        font-weight: normal;
        font-size: 2rem;
        line-height: .8;
        opacity: 0;
      }
    }
    dd{
      padding-left: 1rem;
      border-left: 3px solid #666;
    }
    .faq-close{
      dt{
        &::after{
          opacity: 1;
        }
      }
      dd{
        display: none;
      }
    }
  }

  #contact{
    background: #eee;
    p{
      font-size: 1.2rem;
    }
    p.note{
      font-size: .85rem;
    }
    p#mail-form-footer{
      .btn{
        font-size: 1.2rem;
      }
    }
    #mail_form{
      dt{
        .required{
          color: #f33;
          font-size: .9rem;
        }
      }
      dd{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        @include mq('min', 'md') {
          flex: 0 0 66.66667%;
          max-width: 66.66667%;
        }
        @include mq('max', 'md') {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .error_blank{
          color: #f33;
          font-size: .9rem;
        }
      }
    }
  }
}

footer{
  .copyright{
    font-size: .7rem;
  }
}
